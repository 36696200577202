import { Link } from "gatsby"
import { useStaticQuery,graphql } from "gatsby"
import React from "react"

import Img from "gatsby-image"
import Waypoint from 'react-waypoint';


export default () => {

  const data = useStaticQuery(graphql`
  query {
    allFile(filter:{extension:{regex:"/(jpeg|jpg|gif|png)/"},  sourceInstanceName:{eq:"oldportfolio"}}) {
        edges {
          node {
            childImageSharp {
                fluid(maxWidth: 600,quality:86) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                  
                  sizes(maxWidth: 600) {
                    ...GatsbyImageSharpSizes
                  }
            }
          }
        }
      }
  }
`)

const myref = React.createRef();

const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}


const Posts = data.allFile.edges
.map((a) => ({sort: Math.random(), value: a}))
.sort((a, b) => a.sort - b.sort)
.map((a) => a.value)

.map(function(edge){

   //let liclass=( edge.node.frontmatter.path != currentpath ) ? '':'current';
    
    return <div className="op"><Img fluid={edge.node.childImageSharp.fluid}></Img></div>
    


  }
)


const clickHandler = (e) =>{

  
    e.currentTarget.classList.toggle('hideall')
  }


const wpOnEnter = function (e) {
  
    myref.current.classList.toggle('hideall')

    //console.log("onenterXXX",myref.current)
    this.setState({
      isInView : 'active'
    })
};



const wpOnLeave = function (e) {
    //myref.current.classList.remove('showall')
    //myref.current.classList.remove('hideall')
    //console.log("onLeaveXXXX",e)
    this.setState({
      isInView : 'active'
    })
};

  return (
    <Waypoint onEnter={wpOnEnter} onLeave={wpOnLeave}>
    <div ref={myref} className='imagegrid hideall'  onClick={clickHandler}  >
      {Posts}
    </div>
    </Waypoint>
  )
}


