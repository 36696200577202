import React from "react"
import { Link,graphql } from "gatsby"
import WaypointView from '../components/waypointview';
import Layout from "../components/layout"
import ImageClients from "../components/imageclients"
import SEO from "../components/seo"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import OldPortfolio from "../components/oldportfolio"
import shanghai from "./../images/shwhite.svg"

import ConsoleView from '../components/consoleview'

class IndexPage extends React.Component  {

  constructor(props) {
    super(props);

    this.state = {
      inView: false,
    };
      

      //console.log(this.state,this.inView)
      
    
  }

  render (){

const clickHandler = (e) =>{

  
  e.currentTarget.classList.toggle('showall')
}




  return (
  <Layout>
    <SEO title="u-naction" keywords={['webdesign', 'application', 'development']} />
    
    <div class='terminal'>
        <ConsoleView><span className='acc'>Hi, I am ux architect and product designer</span><span>and I develop end-to-end, tailor-made online solutions:</span></ConsoleView>
        
        </div>
    <div className='servicecontainer'>
    <div className='services home'>

    <div className='service'>
    <div class='visual cswapp'></div>
    <div class='content'>
        <h3>web applications</h3>
        <p>Booking & reservation platforms, web portals, and tailor-made content management systems</p>
        <AniLink cover bg="#000000" className='readmoretxt' to="/casestudies/ccc">APP CASE STUDIES</AniLink>
        </div>
        

      </div>

    <div className='service'>
    <div class='visual csui'></div>
    <div class='content'>
        <h3>user interfaces</h3>
        <p>Data-driven complex interfaces and dashboards where IA is more than just a sitemap</p>
        <AniLink cover bg="#000000" className='readmoretxt' to="/casestudies/gojoy">UX/UI CASE STUDIES</AniLink>
        </div>
      </div> 


    <div className='service'>
    <div class='visual csweb'></div>
    <div class='content'>
        <h3>websites</h3>
        <p>Corporate & less corporate websites. Powered by Wordpress or delivered as JAM stack</p>
        <AniLink cover bg="#000000" className='readmoretxt' to="/casestudies/ultraviolet">WEB CASE STUDIES</AniLink>
        </div>
      </div>

 
      <div className='clearfix'></div>    
    </div>
</div>

{/*
<div class='imagegrid' onClick={clickHandler}>

<img src='pp.png' />
<img src='polytek.pp_.png' />
<img src='cc.png' />
<img src='uv.png' />
<img src='lh-1.png' />
<img src='hod.png' />
<img src='ash.png' />
<img src='xturbo.png' />
<img src='awuth.png' />
<img src='sf.png' />
<img src='mmbresa.png' />
<img src='uvbooking.png' /> 
</div>
*/}
<WaypointView always='never'><span className='acc'>about </span><span>DESIGN</span></WaypointView>
    <div className='services'>
      <p className='col75'><em><strong>user Experience & product Design</strong></em><br/>
      I am a digital designer specializing in Information Architecture and Interaction Design. Sometimes do UI. I also have strong and practical technical experience helping me to deliver the highest-fidelity prototypes or MVPs.    </p>
    </div>

<WaypointView always='never'><span className='acc'>about </span><span>TECH</span></WaypointView>
    <div className='services'>
      <p className='col75'><em><strong>beyond WordPress and H5</strong></em><br/>
      It's been a long front-end journey that started with Flash and AS3 many years ago.
      <br/>Recently working most of the time with Vue and Nuxt. I am a big fan of Netlify and get frustrated when the Chrome autocompletes it to Netflix.  
      </p>
    </div>
    <WaypointView always='never'><span className='acc'>past </span><span>PROJECTS</span></WaypointView>
    
    <OldPortfolio />
    <div className="services centered">
    <AniLink cover bg="#000000" className='readmoreportfolio' to="/casestudies/ccc">BROWSE CASE STUDIES</AniLink>
    </div>
    
    

    <WaypointView always='never'><span className='acc'>get</span><span> IN </span><span className='acc'>touch</span></WaypointView>
    <div className='services' id="contact">
      <p className='col75' Style='text-align:center;'>
Thanks for scrolling that far :)<br/>
My name is Kristof and I am currently working remotely from Poland.<br/><br/>
You can contact me via <a href='mailto:krawieckrzysztof@gmail.com'>email</a></p>
    </div>

  </Layout>
)}}

export default IndexPage


export const query = graphql`
{
  allFile(filter:{extension:{regex:"/(jpeg|jpg|gif|png)/"},  sourceInstanceName:{eq:"oldportfolio"}}) {
    edges {
      node {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  }
}
`