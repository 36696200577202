import React, {Component} from 'react';


class ConsoleView extends Component {

    constructor(props) {
        
        super(props);
        

        this.th1 = React.createRef();
        this.th2 = React.createRef();
      
        var newText = '';
        var fullText = '';
        var fullSubText= '';

   
        this.props.children.forEach(function(child){


            //console.log(child.type,child.props.className,child.props.children)

            var str = ''+child.props.children;
            
            for(var i=0;i<str.length;i++){
                let s = str[i];
                if(child.props.className === 'acc') {
                    newText+='<span class="acc">'+s+'</span>';
                    fullText+=s;
                    

                }else {
                    newText+='<span>'+s+'</span>';
                    fullSubText+=s;
                }
            }


        })

        
        this.state = {
            isInView : (this.props.always)?'active':'',
            newText : newText,
            fullText : fullText,
            fullSubText : fullSubText,
            tempText : '',
            tempSubText : '',
            position:0,
            positionsub:0,
            myVar : null,
            th1:this.th1,th2:this.th2

        }
        

        
        
        
   
        
    }//eof constructor
    componentDidMount () {

        
        

        this.state.myVar = setInterval(()=>{
            //console.log(this.state.position,"ss")
            if(this.state.position<this.state.fullText.length ){

                
                this.setState({tempText:this.state.tempText+ this.state.fullText[this.state.position]});

                this.setState({position: this.state.position+1});
            }else{
                this.setState({position: this.state.position+1});
                if(this.state.position>100){
                    this.state.position=0;
                    this.state.tempText='';

                }
            }
            

            if(this.state.positionsub<this.state.fullSubText.length ){

                    this.setState({tempSubText:this.state.tempSubText+ this.state.fullSubText[this.state.positionsub]});

                this.setState({positionsub: this.state.positionsub+1});
            }else{
                this.setState({positionsub: this.state.positionsub+1});
                if(this.state.positionsub>100){
                    this.state.positionsub=0;

                    this.state.tempSubText='';
                }
            }


        
        }, 100);

        this.initialize();
    }

    componentDidUpdate() {

        
    };

    initialize(){
        
        //showing always width of h1 as 0
        //console.log("xxxxxxxa",this.th1.current.getBoundingClientRect())
        //console.log("xxxxxxxa",this.th1.current.parentElement.clientWidth)

    };

    componentWillUnmount ()  {
        clearTimeout(this.state.myVar);
       };

    render() {
        
        return (
<div>
           <h1 ref={this.th1} className='console' dangerouslySetInnerHTML={{ __html: this.state.tempText }}></h1>
           <br class='hidemobile'/>
           <h2 ref={this.th2} className='console' dangerouslySetInnerHTML={{ __html: this.state.tempSubText }}></h2>
           </div>
        );
    }



}

export default ConsoleView;